
import '../scss/styles.scss'

import 'babel-polyfill'

import 'lazyload/lazyload.min'
import 'tiny-slider/dist/tiny-slider.css'

import './src/common'
import './src/index-page'
import './src/cookies'
import './src/select-box'
