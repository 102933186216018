document.addEventListener("DOMContentLoaded", function() {
    const selects = document.querySelectorAll('.js-select-box');

    selects.forEach(select => {
        select.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        const button = select.querySelector('.js-select-box-button');
        const holder = select.querySelector('.js-select-box-holder');
        const list = select.querySelector('.js-select-box-list');

        button.addEventListener('click', (e) => {
            if (button.classList.contains('is-state-opened')) {
                holder.classList.remove('is-state-visible');
                list.classList.remove('is-state-visible');
                holder.classList.remove('is-state-opened');

                setTimeout(()=> {
                    holder.style.display = 'none';
                }, 300);
            } else {
                holder.style.display = 'block';

                setTimeout(()=> {
                    holder.classList.add('is-state-opened');
                }, 10);

                setTimeout(()=> {
                    list.classList.add('is-state-visible');
                    holder.classList.add('is-state-visible');
                }, 350);
            }

            button.classList.toggle('is-state-opened');
        });
    });

    document.addEventListener('click', function(event) {
        selects.forEach(select => {
            const holder = select.querySelector('.js-select-box-holder');

            holder.classList.remove('is-state-visible');
            select.querySelector('.js-select-box-list').classList.remove('is-state-visible');
            holder.classList.remove('is-state-opened');

            setTimeout(()=> {
                holder.style.display = 'none';
            }, 300);

            select.querySelector('.js-select-box-button').classList.remove('is-state-opened');
        });
    });
});