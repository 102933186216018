document.addEventListener('DOMContentLoaded', () => {
    const customizeCookiesButtons = document.querySelectorAll('.js-customize-cookies-button');
    const cookiesDialog = document.getElementById('cookiesDialog');
    const cookiesDialogForm = document.getElementById('cookiesDialogForm');
    const closeButtons = document.querySelectorAll('.js-cookies-dialog-close');

    const openCookiesDialog = () => {
        customizeCookiesButtons.forEach(button => {
            button.addEventListener('click', ()=> {
                cookiesDialog.classList.add('is-state-open');

                setTimeout(()=> {
                    cookiesDialog.classList.add('is-state-visible');
                }, 10);
            });
        });
    };

    const closeCookiesDialog = () => {
        closeButtons.forEach(button => {
            button.addEventListener('click', ()=> {
                cookiesDialog.classList.remove('is-state-visible');

                setTimeout(()=> {
                    cookiesDialog.classList.remove('is-state-open');
                }, 300);
            });
        })
    }

    const doDialogFormClick = ()=> {
        if (!cookiesDialogForm) {
            return;
        }

        cookiesDialogForm.addEventListener('click', (event)=> {
            event.stopPropagation();
        })
    }

    openCookiesDialog();
    closeCookiesDialog();
    doDialogFormClick();
});
