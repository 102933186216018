import {tns} from "tiny-slider";

window.addEventListener('load', ()=> {
    mobilePromoSliderInit();
});

const mobilePromoSliderInit = ()=> {
    if (!document.getElementById('indexPromoMobileCarousel')) {
        return;
    }

    const slider = tns({
        container: '#indexPromoMobileCarousel',
        prevButton: '#indexPromoCarouselPrevButton',
        nextButton: '#indexPromoCarouselNextButton',
        items: 1,
        loop: false,
        gutter: 0,
        nav: false
    });

    const getActiveItem = (isInitial = false)=> {
        if (isInitial) {
            document.getElementById('indexPromoCarouselTotalSlides').innerHTML = `${slider.getInfo().slideCount}`;
        }

        document.getElementById('indexPromoCarouselCurrentSlide').innerHTML = `${slider.getInfo().index + 1}`;
    }

    getActiveItem(true);

    slider.events.on('indexChanged', ()=> {
        getActiveItem();
    });
}